/* eslint-disable indent */
import { FilterType, DataType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const detailHeaders = [
    {
        text: i18n.t('onBoardMonitor.headers.event'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'eventNumber',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.rus'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'rus',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.sector'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'sector',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.vehicleType'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'type',
        width: 150
    },
    {
        text: i18n.t('onBoardMonitor.headers.vehicles'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'thingName',
        width: 120
    },
    {
        text: i18n.t('onBoardMonitor.headers.date'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'timestamp',
        width: 250
    },
    {
        text: i18n.t('onBoardMonitor.headers.os'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'stateOS',
        width: 110
    },
    {
        text: i18n.t('onBoardMonitor.headers.logIt'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'login',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.driver'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverName',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.monitorEvent'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'onBoardEvent',
        width: 280
    },
    {
        text: i18n.t('onBoardMonitor.headers.insum'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'supply',
        width: 170
    },
    {
        text: i18n.t('onBoardMonitor.headers.lts'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'lts',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.price'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'price',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.identificator'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'nroOS',
        width: 170
    },
    {
        text: i18n.t('onBoardMonitor.headers.unitOrPlace'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'place',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.rpm'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'rpm',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.engine'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'engine',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.velocity'),
        align: 'left',
        filterType: FilterType.textField,
        options: [],
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'speed',
        width: 120
    },
    {
        text: i18n.t('onBoardMonitor.headers.anchor'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workWidth',
        width: 160
    },
    {
        text: i18n.t('onBoardMonitor.headers.activity'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'activity',
        width: 300
    },
    {
        text: i18n.t('onBoardMonitor.headers.position'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.array,
        value: 'position',
        width: 160
    }
]

const summaryHeaders = [
    {
        text: i18n.t('onBoardMonitor.headers.sector'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'sector',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.activity'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'activity',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.type'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'thingType',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.vehicles'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'thingName',
        width: 140
    },
    {
        text: i18n.t('onBoardMonitor.headers.rrhh'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverName',
        width: 100
    },
    {
        text: i18n.t('onBoardMonitor.headers.pauseType'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'type',
        width: 200
    },
    {
        text: i18n.t('onBoardMonitor.headers.pause'),
        align: 'left',
        filterType: FilterType.textField,
        options: [],
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'name',
        width: 300
    },
    {
        text: i18n.t('onBoardMonitor.headers.start'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'start',
        width: 300
    },
    {
        text: i18n.t('onBoardMonitor.headers.end'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'end',
        width: 300
    },
    {
        text: i18n.t('onBoardMonitor.headers.time'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'totalTime',
        width: 120
    },
    {
        text: i18n.t('onBoardMonitor.headers.percentage'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'percentage',
        width: 120
    },
    {
        text: i18n.t('onBoardMonitor.headers.position'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.array,
        value: 'position',
        width: 160
    },
    {
        text: i18n.t('onBoardMonitor.headers.driveUnit'),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driveUnitName',
        width: 200
    }
]

export default {
    detailHeaders,
    summaryHeaders
}
