/* eslint-disable indent */
import {
    EVENT_TYPE_KEY, ReportConversionUtil, Util,
    ValueText, Axes, Chart, ChartType, Dataset, Options,
    OnBoardMonitorReportConstants
} from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'
import onBoardMonitorReportApi from '@/api/onBoardMonitorReport.api'
import onBoardMonitorReportConstants from '../constants/onBoardMonitorReport.constants'
import { getTimeOffset } from '@/tools/functions'

/**
 * Obtener datos para el detalle del reporte monitor a bordo
 * @param {*} id
 * @param {*} from
 * @param {*} to
 * @param {*} sector
 * @param {*} activity
 * @param {*} type
 * @returns
 */
const getDetail = async (id, from, to, sector, activity, type) => {
    let data
    try {
        const response = await onBoardMonitorReportApi.getDetail(id, from, to, sector, activity, type)
        data = response.data
        const offset = getTimeOffset()
        const lang = localStorage.getItem('locale')
        for (const element of data) {
            element.type = element.type && element.type[lang]
            const timestamp = ReportConversionUtil.applyTimezone(element.timestamp, offset)
            element.timestamp = timestamp.dateString + ' ' + timestamp.timeString
            let prefix = ''
            switch (element.eventType) {
                case 'PAUSE':
                    prefix = ((element.onBoardEvent && element.onBoardEvent.start) ? i18n.t('onBoardMonitor.headers.start') : i18n.t('onBoardMonitor.headers.end'))
                    element.onBoardEvent = prefix + ' ' + element.onBoardEvent.name
                    break
                case EVENT_TYPE_KEY.LOGIN:
                    element.login = i18n.t('onBoardMonitor.data.login')
                    break
                case EVENT_TYPE_KEY.LOGOFF:
                    element.login = i18n.t('onBoardMonitor.data.logof')
                    break
                case EVENT_TYPE_KEY.SERVICE_ORDER:
                    element.stateOS = element.stateOS === '1'
                        ? i18n.t('onBoardMonitor.data.stateInit')
                        : i18n.t('onBoardMonitor.data.stateEnd')
                    break
            }
            element.position = element.position ? new ValueText(element.position, JSON.stringify(element.position)) : undefined
            if (element.driverName == null) {
                element.driverName = element.driverCode
                    ? i18n.t('onBoardMonitor.unidentifiedDriverWithDriverCode', { driverCode: element.driverCode })
                    : i18n.t('onBoardMonitor.unidentifiedDriver')
            }
            // actividad de orden de servicio no identificada
            if (element.unknownActivity) {
                element.activity = i18n.t('onBoardMonitor.unidentifiedActivity', { activityId: element.activityId })
            }
        }
    } catch (exception) {
        console.error(exception)
    }
    return {
        data: data || [],
        headers: onBoardMonitorReportConstants.detailHeaders
    }
}

/**
 *  Generar reporte no diferido
 * @param {*} sectorId
 * @param {*} activityId
 * @param {*} typeKey
 * @param {*} thingsId
 * @param {*} from
 * @param {*} to
 * @param {*} timeFormat
 * @returns
 */
const getSummaryDirect = async (sectorId, activityId, typeKey, thingsId, from, to, timeFormat) => {
    let processedData
    try {
        const response = await onBoardMonitorReportApi.getSummary({ sectorId, activityId, typeKey, thingsId, from, to })
        const { tableData, pauseCharts } = response.data
        processedData = processSummary(tableData, pauseCharts, from, to, timeFormat)
    } catch (exception) {
        console.error(exception)
    }
    return {
        data: processedData != null && processedData.tableData != null ? processedData.tableData : [],
        pauseCharts: processedData != null && processedData.pauseCharts != null ? processedData.pauseCharts : [],
        headers: onBoardMonitorReportConstants.summaryHeaders
    }
}

/**
 * Generar reporte diferido
 * @param {*} filters
 */
const getSummaryDeferred = (filters, route) => {
    onBoardMonitorReportApi.getSummaryDefered(filters, route)
}

/**
 * Procesar los datos para el resumen
 * @param {*} tableData
 * @param {*} pauseCharts
 * @param {*} from
 * @param {*} to
 * @param {*} timeFormat
 * @returns
 */
const processSummary = (tableData, pauseCharts, from, to, timeFormat) => {
    const timeOffset = getTimeOffset()
    const lang = localStorage.getItem('locale')
    tableData.forEach(d => {
        if (d.start) {
            const start = ReportConversionUtil.applyTimezone(d.start, timeOffset)
            d.start = start.dateString + ' ' + start.timeString
        }
        if (d.end) {
            const end = ReportConversionUtil.applyTimezone(d.end, timeOffset)
            d.end = end.dateString + ' ' + end.timeString
        }
        d.totalTime = ReportConversionUtil.secondsToStringFormatted(d.totalTime, timeFormat.value)
        d.percentage = Util.roundDecimals(d.percentage) + '%'
        d.type = d.type ? i18n.t('onBoardMonitor.pause.' + d.type) : undefined
        if (d.name === OnBoardMonitorReportConstants.UNDEFINED_PAUSE || d.name === OnBoardMonitorReportConstants.NO_DATA_PAUSE) {
            const translation = d.name === OnBoardMonitorReportConstants.UNDEFINED_PAUSE ? 'UNDEFINED' : 'NO_DATA'
            d.name = i18n.t(`onBoardMonitor.pause.${translation}`)
            d.driver = i18n.t('onBoardMonitor.unidentifiedDriver')
        }
        if (d.driverName == null) {
            d.driverName = d.driverCode
                ? i18n.t('onBoardMonitor.unidentifiedDriverWithDriverCode', { driverCode: d.driverCode })
                : i18n.t('onBoardMonitor.unidentifiedDriver')
        }
        d.position = d.position ? new ValueText(d.position, JSON.stringify(d.position)) : undefined
        d.thingType = d.thingType ? d.thingType[lang] : undefined
    })

    return {
        tableData,
        pauseCharts: getPauseCharts(pauseCharts, from, to, false, timeOffset, timeFormat.value)
    }
}

/**
 * Generar todos los gráficos de pausas
 * @param {*} data
 * @param {*} from
 * @param {*} to
 * @param {*} percentage
 * @param {*} offset
 * @returns
 */
const getPauseCharts = (data, from, to, percentage, offset, timeFormat) => {
    let noDataGroup = data.groupByFarm.find(d => d.id === OnBoardMonitorReportConstants.NO_FARM_GROUP)
    if (noDataGroup != null) {
        noDataGroup.name = i18n.t('onBoardMonitor.dashboard.noFarmGroup')
    }
    noDataGroup = data.groupByWorkFront.find(d => d.id === OnBoardMonitorReportConstants.NO_WORK_FRONT_GROUP)
    if (noDataGroup != null) {
        noDataGroup.name = i18n.t('onBoardMonitor.dashboard.noWorkFrontGroup')
    }
    noDataGroup = data.groupByRRHH.find(d => d.id === OnBoardMonitorReportConstants.NO_RRHH_GROUP)
    if (noDataGroup != null) {
        noDataGroup.name = i18n.t('onBoardMonitor.dashboard.noRRHHGroup')
    }
    const pauseColor = {}

    const farmChartData = getPauseBarChart(
        data.groupByFarm, 'pauseFarms', i18n.t('onBoardMonitor.dashboard.farm'), percentage,
        from, to, offset, pauseColor, timeFormat)

    const workFrontChartData = getPauseBarChart(
        data.groupByWorkFront, 'pauseWorkFronts', i18n.t('onBoardMonitor.dashboard.workFront'), percentage,
        from, to, offset, pauseColor, timeFormat)

    const machineChartData = getPauseBarChart(
        data.groupByMachine, 'pauseMachines', i18n.t('onBoardMonitor.dashboard.equipment'), percentage,
        from, to, offset, pauseColor, timeFormat)

    const rrhhChartData = getPauseBarChart(
        data.groupByRRHH, 'pauseRRHH', i18n.t('onBoardMonitor.dashboard.driver'), percentage,
        from, to, offset, pauseColor, timeFormat)

    const farms = data.groupByFarm.map(d => ({ id: d.id, name: d.name }))
    const workFronts = data.groupByWorkFront.map(d => ({ id: d.id, name: d.name }))
    const machines = data.groupByMachine.map(d => ({ id: d.id, name: d.name, typeKey: d.typeKey }))
    const rrhhs = data.groupByRRHH.map(d => ({ id: d.id, name: d.name }))

    return {
        charts: [farmChartData.chart, workFrontChartData.chart, machineChartData.chart, rrhhChartData.chart],
        data: [farmChartData.data, workFrontChartData.data, machineChartData.data, rrhhChartData.data],
        farms,
        workFronts,
        machines,
        rrhhs
    }
}

/**
 * Generar gráfico de barra de pausas
 * @param {*} data
 * @param {*} id
 * @param {*} name
 * @param {*} percentage
 * @param {*} from
 * @param {*} to
 * @param {*} offset
 * @param {*} pauseColor
 * @param timeFormat
 * @returns
 */
const getPauseBarChart = (data, id, name, percentage, from, to, offset, pauseColor, timeFormat) => {
    // creo los datasets y agrego los labels
    const datasets = []
    const labels = []
    let dataset
    let newDataset

    data.forEach((dataObject, index) => {
        dataObject.pauses.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })

        // recorro los datos de cada estado para agregarlo al dataset correspondiente
        dataObject.pauses.forEach(pause => {
            dataset = datasets.find(d => d.id === pause.name)
            pause.percentage = new ValueText(pause.percentage, `${Util.roundDecimals(pause.percentage)} %`)
            pause.totalTime = new ValueText(pause.totalTime, ReportConversionUtil.secondsToStringFormatted(pause.totalTime, timeFormat))

            if (dataset) {
                if (percentage) {
                    dataset.data[index] = pause.percentage.value
                    dataset.formattedTooltipData.label[index] = pause.percentage.text
                    dataset.formattedLabelData[index] = pause.percentage.text
                } else {
                    dataset.data[index] = pause.totalTime.value
                    dataset.formattedTooltipData.label[index] = pause.totalTime.text
                    dataset.formattedLabelData[index] = pause.totalTime.text
                }
                pause.color = pause.color ? pause.color : dataset.borderColor
            } else {
                newDataset = new Dataset()
                newDataset.id = pause.name
                data.forEach(d => {
                    if (d.id === dataObject.id) {
                        if (percentage) {
                            newDataset.data.push(pause.percentage.value)
                            newDataset.formattedTooltipData.label.push(pause.percentage.text)
                            newDataset.formattedLabelData.push(pause.percentage.text)
                        } else {
                            newDataset.data.push(pause.totalTime.value)
                            newDataset.formattedTooltipData.label.push(pause.totalTime.text)
                            newDataset.formattedLabelData.push(pause.totalTime.text)
                        }
                    } else {
                        newDataset.data.push(0)
                        if (percentage) {
                            newDataset.formattedTooltipData.label.push('0 %')
                            newDataset.formattedLabelData.push('0 %')
                        } else {
                            newDataset.formattedTooltipData.label.push(pause.totalTime.text.replace(/\d+/g, '0'))
                            newDataset.formattedLabelData.push(pause.totalTime.text.replace(/\d+/g, '0'))
                        }
                    }
                })

                let color
                if (pause.color) {
                    color = pause.color
                } else {
                    if (pauseColor[pause.name]) {
                        color = pauseColor[pause.name]
                    } else {
                        color = Util.pickColor(datasets.map(ds => ds.borderColor))
                        pauseColor[pause.name] = color
                    }
                }
                pause.color = color
                newDataset.backgroundColor = color
                newDataset.borderColor = color
                if (pause.name === OnBoardMonitorReportConstants.UNDEFINED_PAUSE) {
                    newDataset.label = i18n.t('onBoardMonitor.pause.UNDEFINED')
                } else if (pause.name === OnBoardMonitorReportConstants.NO_DATA_PAUSE) {
                    newDataset.label = i18n.t('onBoardMonitor.pause.NO_DATA')
                } else {
                    newDataset.label = pause.name
                }

                datasets.push(newDataset)
            }
        })

        // labels
        labels.push(dataObject.name)
    })

    const yAxes = new Axes(ChartType.BAR)
    yAxes.stacked = true
    const xAxes = new Axes(ChartType.BAR)
    xAxes.gridLines.display = false
    xAxes.stacked = true
    const options = new Options()
    options.tooltips.mode = 'x'
    const chart = new Chart()

    // id
    chart.id = id
    // nombre
    chart.name = name
    // tipo
    chart.type = ChartType.BAR
    // agrego los datasets y labels
    Array.prototype.push.apply(chart.data.datasets, datasets)
    Array.prototype.push.apply(chart.data.labels, labels)

    // Options
    const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
    const toConversion = ReportConversionUtil.applyTimezone(to, offset)
    const title = fromConversion.dateString + ' ' +
        fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
    options.timeFormat = timeFormat
    options.title.text = title
    // ejes
    xAxes.scaleLabel.labelString = name
    yAxes.scaleLabel.labelString = percentage ? i18n.t('onBoardMonitor.dashboard.percentage') : i18n.t('onBoardMonitor.dashboard.time')
    options.scales.yAxes.push(yAxes)
    options.scales.xAxes.push(xAxes)

    // deshabilito animaciones
    options.animation = {
        duration: 0
    }
    options.hover = {
        animationDuration: 0
    }
    options.responsiveAnimationDuration = 0

    chart.options = options
    chart.showDatalabels = true
    chart.timeDurationY = !percentage
    return { chart, data }
}

/**
 * Generar todos los gráficos para el dashboard de estados de equipos que está embebido en el reporte
 * @param {*} typeKey
 * @param {*} things
 * @param {*} from
 * @param {*} to
 * @param {*} timeRange
 * @param timeFormat
 * @returns
 */
const getStateCharts = async (typeKey, things, from, to, timeRange, timeFormat) => {
    /*
        ACTUALMENTE EL REPORTE SE GENERA PARA UN SOLO TIPO DE EQUIPO, SI EN EL FUTURO SE
        CAMBIA PARA SOPORTAR MÚLTIPLES TIPOS, LO SIGUIENTE DEBERÁ MODIFICARSE
    */
    const typeKeys = [typeKey]
    let result = null
    try {
        const response = await onBoardMonitorReportApi.getStateCharts(typeKeys, things, from, to, timeRange, timeFormat.value)
        result = response.data
    } catch (exception) {
        console.error(exception)
    }
    return result
}

/**
 * Generar todos los gráficos para el dashboard de tiempo productivo/improductivo que está embebido en el reporte
 * @param {*} typeKey
 * @param {*} things
 * @param {*} from
 * @param {*} to
 * @param {*} timeRange
 * @param timeFormat
 * @returns
 */
const getProductiveCharts = async (typeKey, things, from, to, timeRange, timeFormat) => {
    /*
        ACTUALMENTE EL REPORTE SE GENERA PARA UN SOLO TIPO DE EQUIPO, SI EN EL FUTURO SE
        CAMBIA PARA SOPORTAR MÚLTIPLES TIPOS, LO SIGUIENTE DEBERÁ MODIFICARSE
    */
    const typeKeys = [typeKey]
    let result = null
    try {
        const response = await onBoardMonitorReportApi.getProductiveCharts(typeKeys, things, from, to, timeRange, timeFormat.value)
        result = response.data
    } catch (exception) {
        console.error(exception)
    }
    return result
}

export default {
    getDetail,
    getSummaryDirect,
    getSummaryDeferred,
    processSummary,
    getStateCharts,
    getProductiveCharts
}
