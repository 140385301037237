import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import onBoardMonitorReportService from '@/business/onBoardMonitorReportService'
import dashboardProductiveTimeService from '@/business/dashboardProductiveTimeService'

export default {
  name: 'MDBProductiveChartComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    filters: {
      required: true
    },
    details: {
      type: String,
      required: true
    }
  },
  data: () => ({
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    selectorsData: [[], [], [], []],
    // arreeglo de los valores seleccionados
    selectorsModel: [null, null, null, null],
    currentChartIndex: 0,
    loading: false,
    intervalId: null,
    // para guardar los datos del dashboard
    data: null,
    // para la opción "todos" de los selectores
    all: {
      id: 'ALL',
      name: i18n.t('all')
    }
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.getData()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    async getData (update = false) {
      if (!this.filters) return
      this.loading = true

      const response = await onBoardMonitorReportService.getProductiveCharts(
        this.filters.typeKey,
        this.filters.things,
        this.filters.from,
        this.filters.to,
        this.filters.selectedDateAndTimeRange,
        this.filters.timeFormat)

      if (response != null) {
        const { data, charts, farms, workFronts, machines, rrhhs } = response

        // actualizo los datos del gráfico y guardo los datasets
        this.chartContainerModel.charts.cleanAndUpdate(charts)
        this.data = data

        // actualizo la lista de datos para los selectores
        this.selectorsData[0].cleanAndUpdate([this.all].concat(farms))
        this.selectorsData[1].cleanAndUpdate([this.all].concat(workFronts))
        this.selectorsData[2].cleanAndUpdate([this.all].concat(machines))
        this.selectorsData[3].cleanAndUpdate([this.all].concat(rrhhs))

        // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
        this.selectorsModel.forEach((model, index) => {
          if (!this.selectorsData[index].find(item => item.id === model)) {
            model = null
          }
        })

        if (update) {
          // MODIFICACIÓN DE LOS FILTROS

          // si es una actualización (se modificaron los filtros, actualizo los datos del selector actual)
          this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[this.currentChartIndex])
          this.chartContainerModel.selectorModel = this.selectorsModel[this.currentChartIndex]
          this.reloadChart(this.currentChartIndex)

          // update
          this.chartContainerModel.update = true
          const that = this
          Vue.nextTick(function () {
            that.chartContainerModel.update = false
          })
        } else {
          // PRIMERA CARGA DE LOS GRÁFICOS

          this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[0])
          this.chartName = this.chartContainerModel.charts[0].name
          this.reloadChart(0)
        }
      }
      this.loading = false

      // auto-reporte
      if (this.filters.autoReport) {
        const that = this
        that.intervalId = setInterval(async function () {
          await that.updateAllCharts()
        }, that.filters.autoReportMinutes * 60 * 1000)
      }
    },
    /**
     * Cuando se cambia el switch de porcentaje, se ejecuta esta función
     * para cambiar los datasets y otras configs del gráfico
     */
    switchPercentage (percentage) {
      if (this.data) {
        this.chartContainerModel.charts.forEach((chart) => {
          chart.timeDurationY = !percentage
          chart.options.scales.yAxes[0].scaleLabel.labelString = percentage
            ? this.$t('dashboard.percentage')
            : this.$t('dashboard.time')
        })
        this.filterCurrentChart(this.currentChartIndex, percentage)
      }
    },
    /**
     * Para filtrar los datos del gráfico de acuerdo al selector
     * @param {*} index
     * @param {*} percentage
     */
    filterCurrentChart (index, percentage) {
      if (!this.chartContainerModel.selectorModel || this.chartContainerModel.selectorModel === 'ALL') {
        const { labels, datasets } = dashboardProductiveTimeService.generateDatasets(this.data[this.currentChartIndex], percentage, null)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      } else {
        const { labels, datasets } = dashboardProductiveTimeService.generateDatasets(this.data[this.currentChartIndex], percentage, this.chartContainerModel.selectorModel)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      }

      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart (data) {
      if (data.index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.chartContainerModel.charts[data.index].name
        // cambio el valor del selector y guardo el valor para el gráfico actual
        this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[data.index])
        // currentChart aún tiene los valores del gráfico anterior, guardo el valor
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
        // actualizo el valor de currentChart
        this.currentChartIndex = data.index
        // actualización del gráfico
        if (this.chartContainerModel.selectorModel !== this.selectorsModel[data.index]) {
          // si hay valor seleccionado, lo seteo
          this.chartContainerModel.selectorModel = this.selectorsModel[data.index]
        } else {
          this.updateChart()
        }
      }
    },
    /**
     * Función ejecutada cuando se actualiza la tabla
     * @param {*} index
     */
    reloadChart (index) {
      // nombre del gráfico (para los detalles)
      this.chartName = this.chartContainerModel.charts[index].name
      // cambio el valor del selector y guardo el valor para el gráfico actual
      this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[index])
      // currentChart aún tiene los valores del gráfico anterior, guardo el valor
      this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
      // actualizo el valor de currentChart
      this.currentChartIndex = index
      // actualización del gráfico
      if (this.chartContainerModel.selectorModel !== this.selectorsModel[index]) {
        // si hay valor seleccionado, lo seteo
        this.chartContainerModel.selectorModel = this.selectorsModel[index]
      } else {
        this.updateChart()
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      if (this.data) {
        this.loading = true

        // filtra los datos del gráfico actual de acuerdo a la selección
        this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

        this.loading = false
      }
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      this.loading = true

      const response = await onBoardMonitorReportService.getProductiveCharts(
        this.filters.typeKey,
        this.filters.things,
        this.filters.from,
        this.filters.to,
        this.filters.selectedDateAndTimeRange,
        this.filters.timeFormat)

      if (response != null) {
        const { data, farms, workFronts, machines, rrhhs } = response

        // datos
        this.data = data

        // actualizo la lista de datos para los selectores
        this.selectorsData[0].cleanAndUpdate([this.all].concat(farms))
        this.selectorsData[1].cleanAndUpdate([this.all].concat(workFronts))
        this.selectorsData[2].cleanAndUpdate([this.all].concat(machines))
        this.selectorsData[3].cleanAndUpdate([this.all].concat(rrhhs))

        // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
        this.selectorsModel.forEach((model, index) => {
          if (!this.selectorsData[index].find(item => item.id === model)) {
            model = null
          }
        })

        // filtra los datos del gráfico actual de acuerdo a la selección
        this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)
      }

      this.loading = false
    }
  },
  watch: {
    'chartContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
      },
      deep: true
    },
    'chartContainerModel.percentageSwitchModel': {
      handler: function (val) {
        this.switchPercentage(val)
      },
      deep: true
    },
    filters: {
      handler: function () {
        this.getData()
      },
      deep: true
    }
  }
}
