import Vue from 'vue'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { getChildrenFromList, getDateTimeRange, selectorDateTimeValidation, getIds, ISODateFromUnixTimestamp } from '@/tools/functions'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'
import activityApi from '@/api/activity.api'
import thingApi from '@/api/thing.api'
import sectorApi from '@/api/sector.api'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import MDBProductiveChartComponent from './productiveChart/MDBProdcutiveChart.vue'
import MDBPauseChartComponent from './pauseChart/MDBPauseChart.vue'
import MDBStateChartComponent from './statesChart/MDBStateChart.vue'
import MDBDetail from './detail/MDBDetail.vue'
import service from '@/middleware'
import selectorService from '@/business/selectorService'
import onBoardMonitorReportService from '@/business/onBoardMonitorReportService'
import onBoardMonitorReportConstants from '@/constants/onBoardMonitorReport.constants'
import { clone } from 'ramda'

export default {
  name: 'MDBReport',
  components: {
    BreadcrumbComponent,
    SelectorComponent,
    DashboardChartContainer,
    MDBProductiveChartComponent,
    MDBPauseChartComponent,
    MDBStateChartComponent,
    MDBDetail
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    intervalId: null,
    loadingSpinner: true,
    loadingTable: false,
    disableRefreshTable: true,
    // modelo de datos para el selector
    selectorModel: selectorService.getSelectorModel(false),
    // filtro de sector
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // filtro de actividad
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // filtro de tipo de equipo
    typeFilter: {
      id: 'thingType',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // filtro de equipos
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // backup de selectores >>>
    filters: {
      selectedDateAndTimeRange: null,
      selectedDateAndTimeRangeCustomType: null,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      from: null,
      to: null,
      sectors: [],
      sectorId: null,
      activities: [],
      activityId: null,
      types: [],
      typeKey: null,
      things: [],
      thingsId: [],
      timeFormat: null,
      autoReport: false,
      autoReportMinutes: 10
    },
    // <<<
    showSelector: false,
    disableSelector: false,
    tabsMdbReport: [
      {
        id: 'tab-summary',
        name: i18n.t('summary')
      }
    ],
    tabSelectedMdbReport: 'tab-summary',
    breadcrumbButtomsMdbReport: [],
    reportDetailModel: {
      loadingSpinner: false
    },
    chartDetails: '',
    middleware: service.http,
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.serviceOrder')}</b>` }],
    // filtros para generar los gráficos
    chartsFilters: null,
    // modelo de los gráficos de tiempo por estado y productivo/improductivo
    chartsModel: {
      activateSpinner: false
    },
    // datos para los gráficos de pausas
    chartsData: null,
    // boolean para deshabilitar gráficos de estados y productivo/improductivo
    disableCharts: false,
    // boolean para ocultar todos los gráficos al inicio
    hideCharts: true
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    // Verifico si hay una clave de reporte como parámetro y cargo ese reporte
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  created () {
    // Agrego el botón del breadcrumb para mostrar/ocultar el selector
    this.breadcrumbButtomsMdbReport.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  beforeDestroy () {
    // Antes de que se destruya la instancia del componente, limpio la variable del contador del autoreporte
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setDetails({ detailData: null })
    this.setWarning(null)
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  methods: {
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails',
      setWarning: 'setWarning'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    /**
     * Cambia de tab (breadcrumb)
     * @param {*} id
     */
    tabSelected (id) {
      // const detailRef = this.$refs['mdb-detail']
      if (id === 'tab-summary') {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = false
        this.loadingSpinner = true
        // Se resetea el breadcrumb al volver al Resumen
        this.tabsMdbReport = [
          {
            id: 'tab-summary',
            name: i18n.t('summary')
          }
        ]
        this.tabSelectedMdbReport = 'tab-summary'

        if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
          this.$store.state.app.entitySelectorItems = []
        }
      } else if (id === 'tab-detail') {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = true
        this.loadingSpinner = false
      }
      this.tabSelectedCurrentStatus = id
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.disableSelector
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this)
      this.activityFilter.selectAction = this.activityFilterCallback.bind(this)
      this.typeFilter.selectAction = this.thingTypeFilterCallback.bind(this)
      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll()
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data)

      if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === 'NO_SECTOR')
        this.sectorFilter.disabled = true
        this.sectorFilter.hide = true
        this.sectorFilterCallback()
      }

      this.selectorModel.filters.push(this.sectorFilter, this.activityFilter, this.typeFilter, this.workFrontFilter)
    },
    async sectorFilterCallback () {
      if (this.sectorFilter.selectedData.length > 0) {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = false
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        // datos para el selector de actividades
        const activitiesResponse = await activityApi.getAllForSelector(this.sectorFilter.selectedData[0].key)
        this.activityFilter.data.cleanAndUpdate(activitiesResponse.data)
        if (activitiesResponse.data.length === 1 && activitiesResponse.data[0].key === 'NO_ACTIVITY') {
          this.activityFilter.selectedData = this.activityFilter.data.filter(s => s.key === 'NO_ACTIVITY')
          this.activityFilter.disabled = true
          // this.activityFilter.hide = true
          this.activityFilterCallback()
        }
      } else {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = true
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
      }
    },
    /**
     * Función que es ejecutada luego de seleccionar las actividades (callback)
     */
    async activityFilterCallback () {
      if (this.activityFilter.selectedData.length > 0) {
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = false
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.activityFilter.selectedData.map(a => a.id), this.sectorFilter.selectedData[0].key)
        this.typeFilter.data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
      }
    },
    async thingTypeFilterCallback () {
      if (this.typeFilter.selectedData.length > 0) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = false
        const thingTypesResponse =
          await thingApi.getWorkFrontsByTypes(this.typeFilter.selectedData.map(t => t.id), this.sectorFilter.selectedData[0].key, this.activityFilter.selectedData[0].id)
        this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.workFrontFilter.disabled = true
        this.workFrontFilter.selectedData = []
      }
    },
    getData (reportKey) {
      if (reportKey) {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        this.loadingTable = true
        this.chartsModel.activateSpinner = true
        this.showSelector = false
        this.disableSelector = false
        this.disableRefreshTable = true
        reportStorageApi.getReport(reportKey).then(response => {
          const { data, filters } = response.data
          this.setFilterData(filters)
          this.tableHeaders.cleanAndUpdate(onBoardMonitorReportConstants.summaryHeaders)
          this.setTableFilterRules(this.tableHeaders)
          const { tableData, pauseCharts } = onBoardMonitorReportService.processSummary(
            data.summary, data.pauseCharts, filters.from, filters.to, filters.timeFormat)
          this.tableData.cleanAndUpdate(tableData)
          this.chartsData = pauseCharts
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.loadingTable = false
          this.chartsModel.activateSpinner = false
        })
      } else {
        this.hideCharts = false
        this.showSelector = true
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * Cambiar de tab, ir al detalle de la máquina clickeada
     * @param {*} data
     */
    clickRow (data) {
      this.setEntityConfiguration({ name: this.$t('devices'), value: 'id', text: 'name' })
      const duplicatedTableThings = this.tableData.map(data => {
        return {
          id: data.thingId,
          name: data.thingName
        }
      })
      const items = [...new Set(duplicatedTableThings)]
      this.setEntitySelectorItems(items)
      this.setSelectedItem({
        id: data.thingId,
        name: data.thingName
      })
      if (!this.tabsMdbReport.find(tab => tab.id === 'tab-detail')) {
        this.tabsMdbReport.push({
          id: 'tab-detail',
          name: i18n.t('detail')
        })
      }
      this.commitVisible({ visible: false })
      this.reportDetailModel.loadingSpinner = true
      this.reportDetailModel = {
        ...this.reportDetailModel,
        ...this.filters
      }
      this.loadingSpinner = false
      this.tabSelectedMdbReport = 'tab-detail'
    },
    /**
     * Acción del botón para actualizar los datos de la tabla
     */
    refreshTable () {
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      this.chartsModel.activateSpinner = true
      const conversion = getDateTimeRange(this.filters.selectedDateAndTimeRange, this.filters.selectedDateAndTimeRangeCustomType,
        this.filters.sinceDate, this.filters.sinceTime, this.filters.toDate, this.filters.toTime)
      this.filters.from = conversion.tFrom
      this.filters.to = conversion.tTo
      onBoardMonitorReportService.getSummaryDirect(
        this.filters.sectorId, this.filters.activityId, this.filters.typeKey, this.filters.thingsId, this.filters.from, this.filters.to, this.filters.timeFormat)
        .then(result => {
          this.tableData.cleanAndUpdate(result.data)
          this.chartsData = result.pauseCharts
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.loadingTable = false
          this.chartsModel.activateSpinner = false
        })
    },
    /**
     * Generar el reporte (acción del botón del selector)
     * @param {*} eventData
     */
    getReport (eventData) {
      // deshabilitar gráficos
      this.disableCharts = eventData.warningAccepted
      // rango de tiempo
      this.filters.selectedDateAndTimeRange = eventData.dateAndTimeRange
      this.filters.selectedDateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.filters.sinceDate = eventData.sinceDate
      this.filters.sinceTime = eventData.sinceTime
      this.filters.toDate = eventData.toDate
      this.filters.toTime = eventData.toTime
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.filters.from = conversion.tFrom
      this.filters.to = conversion.tTo
      // sector
      this.filters.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
      this.filters.sectorId = this.sectorFilter.selectedData[0].id
      // actividad
      this.filters.activities.cleanAndUpdate(this.activityFilter.selectedData)
      this.filters.activityId = this.activityFilter.selectedData[0].id
      // tipo
      this.filters.types.cleanAndUpdate(this.typeFilter.selectedData)
      this.filters.typeKey = this.typeFilter.selectedData[0].key
      // cosas
      this.filters.things = []
      getChildrenFromList(eventData.filters[3].selectedData, this.filters.things)
      this.filters.thingsId = this.filters.things.map(t => t.id)
      // formato de tiempo
      this.filters.timeFormat = eventData.dateAndTimeFormat
      // autoreporte
      this.filters.autoReport = eventData.autoReport
      this.filters.autoReportMinutes = eventData.autoReportMinutes

      const that = this
      if (eventData.autoReport) {
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        that.chartsModel.activateSpinner = true
        that.disableRefreshTable = false
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        onBoardMonitorReportService.getSummaryDirect(
          that.filters.sectorId, that.filters.activityId, that.filters.typeKey, that.filters.thingsId, that.filters.from, that.filters.to, that.filters.timeFormat)
          .then(result => {
            that.tableHeaders.cleanAndUpdate(result.headers)
            that.setTableFilterRules(result.headers)
            that.tableData.cleanAndUpdate(result.data)
            that.chartsData = result.pauseCharts
            if (that.loadingSpinner) that.commitVisible({ visible: false })
            that.loadingTable = false
            that.chartsModel.activateSpinner = false
          })
        clearInterval(that.intervalId)
        that.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(that.filters.selectedDateAndTimeRange, that.filters.selectedDateAndTimeRangeCustomType,
              that.filters.sinceDate, that.filters.sinceTime, that.filters.toDate, that.filters.toTime)
            that.filters.from = conversion.tFrom
            that.filters.to = conversion.tTo
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            that.loadingTable = true
            that.chartsModel.activateSpinner = true
            that.chartsModel.activateSpinner = true
            onBoardMonitorReportService.getSummaryDirect(
              that.filters.sectorId, that.filters.activityId, that.filters.typeKey, that.filters.thingsId, that.filters.from, that.filters.to, that.filters.timeFormat)
              .then(result => {
                that.tableData.cleanAndUpdate(result.data)
                that.chartsData = result.pauseCharts
                that.setReportDetails()
                if (that.loadingSpinner) that.commitVisible({ visible: false })
                that.loadingTable = false
                that.chartsModel.activateSpinner = false
              })
          },
          eventData.autoReportMinutes * 60 * 1000)

        // sobreescribo la variable de los filtros para los componentes de los gráficos
        this.chartsFilters = clone(this.filters)
      } else {
        const filters = {
          sectors: this.sectorFilter.selectedData,
          activities: this.activityFilter.selectedData,
          types: this.typeFilter.selectedData,
          things: this.filters.things,
          from: this.filters.from,
          to: this.filters.to,
          dateTimeRange: this.filters.selectedDateAndTimeRange,
          customDateTimeRangeType: this.filters.selectedDateAndTimeRangeCustomType,
          sinceDate: this.filters.sinceDate,
          sinceTime: this.filters.sinceTime,
          toDate: this.filters.toDate,
          toTime: this.filters.toTime,
          thingsId: this.filters.thingsId,
          sectorId: this.filters.sectorId,
          activityId: this.filters.activityId,
          typeKey: this.filters.typeKey,
          timeFormat: this.filters.timeFormat,
          disableCharts: this.disableCharts
        }
        const routePath = this.$route.path
        onBoardMonitorReportService.getSummaryDeferred(filters, routePath)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }

      this.showSelector = false

      // detalles del reporte
      this.setReportDetails()

      // warning en el breadcrumb
      if (eventData.warningAccepted) {
        this.setWarning(this.$t('onBoardMonitor.warningText'))
      } else {
        this.setWarning(null)
      }
    },
    setReportDetails () {
      const detailData = {
        from: this.filters.from,
        to: this.filters.to,
        activities: this.filters.activities,
        types: this.filters.types,
        things: this.filters.things,
        reportName: this.reportNameForChart
      }
      const dataStructure = [
        { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
        { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
        { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
        { detailDataProp: 'activities', propTitle: this.$t('activity'), propType: 'LIST', propRefs: { list: this.filters.activities, value: 'id', text: 'name' } },
        { detailDataProp: 'types', propTitle: this.$t('headers.thingType'), propType: 'LIST', propRefs: { list: this.filters.types, value: 'id', text: 'name' } },
        { detailDataProp: 'things', propTitle: this.$t('things'), propType: 'LIST', propRefs: { list: this.filters.things, value: 'id', text: 'name' } }
      ]
      this.setDetails({
        detailData,
        dataStructure
      })
      this.chartDetails = this.getDetails
    },
    async setFilterData (filterData) {
      this.disableCharts = filterData.disableCharts
      this.hideCharts = false
      // warning en el breadcrumb
      if (this.disableCharts) {
        this.setWarning(this.$t('onBoardMonitor.warningText'))
      }
      this.filters = {
        from: filterData.from,
        to: filterData.to,
        things: filterData.things,
        thingsId: filterData.thingsId,
        sectors: filterData.sectors,
        sectorId: filterData.sectorId,
        activities: filterData.activities,
        activityId: filterData.activityId,
        types: filterData.types,
        typeKey: filterData.typeKey,
        selectedDateAndTimeRange: filterData.dateTimeRange,
        selectedDateAndTimeRangeCustomType: filterData.customDateTimeRangeType,
        sinceDate: filterData.sinceDate,
        sinceTime: filterData.sinceTime,
        toDate: filterData.toDate,
        toTime: filterData.toTime,
        timeFormat: filterData.timeFormat
      }
      // sobreescribo la variable de los filtros para los componentes de los gráficos
      this.chartsFilters = clone(this.filters)
      if (this.sectorFilter.data.length > 0) {
        this.sectorFilter.selectedData = this.filterSelectedData(this.sectorFilter.data, filterData.sectors)
        await this.sectorFilterCallback()
      }
      if (this.activityFilter.data.length > 0) {
        this.activityFilter.selectedData = this.filterSelectedData(this.activityFilter.data, filterData.activities)
        await this.activityFilterCallback()
      }
      if (this.typeFilter.data.length > 0) {
        this.typeFilter.selectedData = this.filterSelectedData(this.typeFilter.data, filterData.types)
        await this.thingTypeFilterCallback()
      }
      if (this.workFrontFilter.data.length > 0) {
        this.workFrontFilter.selectedData = this.filterSelectedData(this.workFrontFilter.data, filterData.things)
      }
      this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
      this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
      this.selectorModel.sinceDate = filterData.sinceDate
      this.selectorModel.sinceTime = filterData.sinceTime
      this.selectorModel.toDate = filterData.toDate
      this.selectorModel.toTime = filterData.toTime
      this.selectorModel.selectedTimeFormat = filterData.timeFormat

      this.setCustomDateAndTimeFilter(filterData)
      this.setReportDetails()
    },
    setCustomDateAndTimeFilter (filterData) {
      if (filterData.from && filterData.to) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null
          this.selectorModel.toTime = null
        } else {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    /**
     * Filtra los ids seleccionados sólo por aquellos que estén en el arreglo de datos del selector
     * @param {*} data
     * @param {*} selectedData
     */
    filterSelectedData (data, selectedData) {
      const dataIds = []
      getIds(data, dataIds)
      return selectedData.filter(element => dataIds.includes(element.id))
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.sectorFilter.selectedData
        const thingTypeSelected = that.activityFilter.selectedData
        const workFrontSelected = that.typeFilter.selectedData
        const timeFormatSelected = that.selectorModel.selectedTimeFormat
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        that.selectorModel.disableGetReportButton =
        activitySelected.length === 0 || thingTypeSelected.length === 0 ||
          workFrontSelected.length === 0 || customTimeValidation || !timeFormatSelected
      })
    }
  },
  watch: {
    selectorModel: {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
