import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import { Dataset, OnBoardMonitorReportConstants } from '@colven/common-domain-lib/lib'
import { clone } from 'ramda'

export default {
  name: 'MDBPauseChartComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    chartsData: {
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    details: {
      type: String,
      required: true
    }
  },
  data: () => ({
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    selectorsData: [[], [], [], []],
    // arreeglo de los valores seleccionados
    selectorsModel: [null, null, null, null],
    currentChartIndex: 0,
    loading: false,
    intervalId: null,
    // para la opción "todos" de los selectores
    all: {
      id: 'ALL',
      name: i18n.t('all')
    }
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.loadInitialData()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    async loadInitialData () {
      this.chartsDataCloned = clone(this.chartsData)
      if (this.chartsDataCloned == null) return
      this.loading = true

      // actualizo los datos del gráfico y guardo los datasets
      this.chartContainerModel.charts.cleanAndUpdate(this.chartsDataCloned.charts)

      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(this.chartsDataCloned.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(this.chartsDataCloned.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(this.chartsDataCloned.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(this.chartsDataCloned.rrhhs))

      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      // PRIMERA CARGA DE LOS GRÁFICOS
      this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[0])
      this.chartName = this.chartContainerModel.charts[0].name
      this.reloadChart(0)

      this.loading = false
    },
    /**
     * Cuando se cambia el switch de porcentaje, se ejecuta esta función
     * para cambiar los datasets y otras configs del gráfico
     */
    switchPercentage (percentage) {
      if (this.chartsData != null && this.chartsData.data != null) {
        this.chartContainerModel.charts.forEach(chart => {
          chart.timeDurationY = !percentage
          chart.options.scales.yAxes[0].scaleLabel.labelString = percentage
            ? this.$t('dashboard.percentage')
            : this.$t('dashboard.time')
        })
        this.filterCurrentChart(this.currentChartIndex, percentage)
      }
    },
    /**
     * Para filtrar los datos del gráfico de acuerdo al selector
     * @param {*} index
     * @param {*} percentage
     */
    filterCurrentChart (index, percentage) {
      if (!this.chartContainerModel.selectorModel || this.chartContainerModel.selectorModel === 'ALL') {
        const { labels, datasets } = this.generateDatasets(percentage, null)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      } else {
        const { labels, datasets } = this.generateDatasets(percentage, this.chartContainerModel.selectorModel)
        this.chartContainerModel.charts[index].data.datasets = datasets
        this.chartContainerModel.charts[index].data.labels = labels
      }

      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })
    },
    /**
     * Para generar los datasets cuando se filtra o se cambia de modo (porcentaje / tiempo)
     * @param {*} percentage
     * @param {*} id
     */
    generateDatasets (percentage, id) {
      const datasets = []
      const labels = []
      let dataset
      let newDataset
      const filteredData = id
        ? this.chartsDataCloned.data[this.currentChartIndex].filter(d => d.id === id)
        : this.chartsDataCloned.data[this.currentChartIndex]
      filteredData.forEach((dataObject, index) => {
        dataObject.pauses.forEach(pause => {
          dataset = datasets.find(d => d.id === pause.name)
          if (dataset) {
            if (percentage) {
              dataset.data[index] = pause.percentage.value
              dataset.formattedTooltipData.label[index] = pause.percentage.text
              dataset.formattedLabelData[index] = pause.percentage.text
            } else {
              dataset.data[index] = pause.totalTime.value
              dataset.formattedTooltipData.label[index] = pause.totalTime.text
              dataset.formattedLabelData[index] = pause.totalTime.text
            }
          } else {
            newDataset = new Dataset()
            newDataset.id = pause.name
            filteredData.forEach(d => {
              if (d.id === dataObject.id) {
                if (percentage) {
                  newDataset.data.push(pause.percentage.value)
                  newDataset.formattedTooltipData.label.push(pause.percentage.text)
                  newDataset.formattedLabelData.push(pause.percentage.text)
                } else {
                  newDataset.data.push(pause.totalTime.value)
                  newDataset.formattedTooltipData.label.push(pause.totalTime.text)
                  newDataset.formattedLabelData.push(pause.totalTime.text)
                }
              } else {
                newDataset.data.push(0)
                if (percentage) {
                  newDataset.formattedTooltipData.label.push('0 %')
                  newDataset.formattedLabelData.push('0 %')
                } else {
                  newDataset.formattedTooltipData.label.push(pause.totalTime.text.replace(/\d+/g, '0'))
                  newDataset.formattedLabelData.push(pause.totalTime.text.replace(/\d+/g, '0'))
                }
              }
            })
            newDataset.backgroundColor = pause.color
            newDataset.borderColor = pause.color
            if (pause.name === OnBoardMonitorReportConstants.UNDEFINED_PAUSE) {
              newDataset.label = i18n.t('onBoardMonitor.pause.UNDEFINED')
            } else if (pause.name === OnBoardMonitorReportConstants.NO_DATA_PAUSE) {
              newDataset.label = i18n.t('onBoardMonitor.pause.NO_DATA')
            } else {
              newDataset.label = pause.name
            }

            datasets.push(newDataset)
          }
        })

        // labels
        labels.push(dataObject.name)
      })

      return { labels, datasets }
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart (data) {
      if (data.index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.chartContainerModel.charts[data.index].name
        // cambio el valor del selector y guardo el valor para el gráfico actual
        this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[data.index])
        // currentChart aún tiene los valores del gráfico anterior, guardo el valor
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
        // actualizo el valor de currentChart
        this.currentChartIndex = data.index
        // actualización del gráfico
        if (this.chartContainerModel.selectorModel !== this.selectorsModel[data.index]) {
          // si hay valor seleccionado, lo seteo
          this.chartContainerModel.selectorModel = this.selectorsModel[data.index]
        } else {
          this.updateChart()
        }
      }
    },
    /**
     * Función ejecutada cuando se actualiza la tabla
     * @param {*} index
     */
    reloadChart (index) {
      // nombre del gráfico (para los detalles)
      this.chartName = this.chartContainerModel.charts[index].name
      // cambio el valor del selector y guardo el valor para el gráfico actual
      this.chartContainerModel.selectorData.cleanAndUpdate(this.selectorsData[index])
      // currentChart aún tiene los valores del gráfico anterior, guardo el valor
      this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
      // actualizo el valor de currentChart
      this.currentChartIndex = index
      // actualización del gráfico
      if (this.chartContainerModel.selectorModel !== this.selectorsModel[index]) {
        // si hay valor seleccionado, lo seteo
        this.chartContainerModel.selectorModel = this.selectorsModel[index]
      } else {
        this.updateChart()
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      if (this.chartsData != null && this.chartsData.data != null) {
        this.loading = true

        // filtra los datos del gráfico actual de acuerdo a la selección
        this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

        this.loading = false
      }
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      this.loading = true

      // actualizo los datos del gráfico y guardo los datasets
      this.chartContainerModel.charts.cleanAndUpdate(this.chartsDataCloned.charts)

      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(this.chartsData.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(this.chartsData.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(this.chartsData.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(this.chartsData.rrhhs))

      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex, this.chartContainerModel.percentageSwitchModel)

      this.loading = false
    }
  },
  watch: {
    'chartContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        this.selectorsModel[this.currentChartIndex] = this.chartContainerModel.selectorModel
      },
      deep: true
    },
    'chartContainerModel.percentageSwitchModel': {
      handler: function (val) {
        this.switchPercentage(val)
      },
      deep: true
    },
    chartsData: {
      handler: function (newValue, oldValue) {
        this.chartsDataCloned = clone(newValue)
        if (oldValue == null) {
          this.loadInitialData()
        } else {
          this.updateAllCharts()
        }
      },
      deep: true
    }
  }
}
