import service from '@/middleware'

const onBoardMonitorReportApi = {
  async getProductiveCharts (typeKeys, things, from, to, timeRange, timeFormat) {
    const body = { typeKeys, things, from, to, timeRange, timeFormat }
    const result = await service.http.post('/api/on-board-monitor-report/productive-chart', body)
    return result
  },
  async getStateCharts (typeKeys, things, from, to, timeRange, timeFormat) {
    const body = { typeKeys, things, from, to, timeRange, timeFormat }
    const result = await service.http.post('/api/on-board-monitor-report/state-chart', body)
    return result
  },
  async getSummaryDefered (filters, route) {
    const body = {
      reportQueryFilters: {
        sectorId: filters.sectorId,
        activityId: filters.activityId,
        typeKey: filters.typeKey,
        thingsId: filters.thingsId,
        from: filters.from,
        to: filters.to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/on-board-monitor-report/deferred', body)
    return result
  },
  async getSummary (body) {
    const result = await service.http.post('/api/on-board-monitor-report/not-deferred', body)
    return result
  },
  async getDetail (thingId, from, to, sectorId, activityId, typeKey) {
    const body = { thingId, from, to, sectorId, activityId, typeKey }
    const result = await service.http.post('/api/on-board-monitor-report/detail', body)
    return result
  }
}

export default onBoardMonitorReportApi
